html, body {
  width: 100%;
  height: 90vh;
  color: #fff;
  background: linear-gradient(-45deg, #0f0c29, #302b63, #24243e, #1d2671);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  scroll-behavior: smooth;
}

header {
  padding: 0% 5%;
}

section {
  position: relative;
  width: 100%;
  text-align: center;
}

// Background animation

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

// Header

header {
  width: 100%;
  height: 80px;
  background-color: transparent;
  line-height: 80px;
}

.header_title {

  line-height: 120px;
  color: white;
  text-decoration: none;
  font-size: 23px;
  cursor: pointer;
}

header{

  nav {
    float: right;
    line-height: 120px;
    display: flex;

    a {
      text-decoration: none;
      font-size: 23px;
      padding: 0 10px;
      cursor: pointer;
      color: #FFFFFF;

      &:hover {
        text-decoration: none;
        font-size: 23px;
        margin-right: 5%;
        padding: 0 10px;
        cursor: pointer;
        color: #FAB42E;
      }
    }
  }

}


// First section

#index {
  width: 100%;
  height: 100vh;
}

#index-content {
  position: absolute;
  width: 100%;
  top: 30%;
  transform: translateY(-50%);
  text-align: center;
}

#title {
  color: #fff;
  font-family: 'Open Sans';
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 45%;
  right: 0;
  left: 0;
}

#second_title {
  color: #fff;
  font-family: 'Open Sans';
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 75%;
  right: 0;
  left: 0;
}

@media only screen and (max-width: 700px) {

  #title {
    font-size: 2em;
  }

  .header_title {
    font-size:18px;
  }

  header{
    nav {
       a {
         font-size:18px;
       }
    }
  }

  .no-emojis-find-text {
    font-size: 1.8em !important;
  }

  .result-emoji {
    font-size: 5.5em !important;
  }
}

@media only screen and (max-width: 550px) {

  .header_title {
    font-size:22px;
  }

  header{
    nav {
      a {
        font-size:22px;
      }
    }
  }

  .result-emoji {
    font-size: 5em !important;
  }

  .no-emojis-find-text {
    font-size: 1.4em !important;
  }

}

@media only screen and (max-width: 500px) {

  #title {
    font-size: 1.8em;
  }

  .header_title {
    font-size:18px;
  }

  header{
    nav {
      a {
        font-size:18px;
      }
    }
  }
}

@media only screen and (max-width: 385px) {

  #index-content {
    top:33%;
  }

  #second_title {
    font-size: 1.8em;
  }

  .result-emoji {
    font-size: 4em !important;
  }

  .no-emojis-find-text {
    font-size: 1.2em !important;
  }
}

// Begin button

#begin-button {

  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 'Josefin Sans', sans-serif;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;
  cursor: pointer;
  margin-top: 400px;

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 70px;
    height: 70px;
    margin-left: -23px;
    border: 2px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        content: '';
        width: 54px;
        height: 54px;
        box-shadow: 0 0 0 0 rgba(255,255,255,.1);
        border-radius: 100%;
        opacity: 0;
        -webkit-animation: sdb03 3s infinite;
        animation: sdb03 3s infinite;
        box-sizing: border-box;
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        width: 16px;
        height: 16px;
        margin: -12px 0 0 -8px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        box-sizing: border-box;
      }
  }
}

// Second section

#demo {
  min-height: 95vh;
  position: relative;

  input {

    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-size: 20px;
    outline: none;

    &::placeholder {
      color: white;
    }
  }
}

// Emojis

#emojis-result {

  margin-top: 25px;
  margin-bottom: 40px;
}

.emojis-item {

  margin-top: 10px;
  color: white;
}

.no-emojis-find {
  position: absolute;
  width: 100%;
  top: 45%;
  transform: translateY(-50%);
  text-align: center;
}

.no-emojis-find-text {
  color: white;
  font-weight: bold;
  font-size: 2.5em;
}

// Search bar

.group-input {
  position: relative;
  width: 95%;
}

#loader-input {
  bottom: 13px;
  right: 10px;
  position: absolute;
}


.ant-message .anticon {
  top: -2px !important;
  margin-right: 10px !important;
}

.center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.result-emoji {
  font-size: 6em;
  color: initial;
  margin-bottom: 0px;
}

.header-text {
  color: aquamarine;
  text-align: center;
  margin-top: 40px;
  font-size: 25px;
}


